import React from "react";

const GetProto = () => {
  return (
    <div className="ee_wrapper">
      <section className="work-part pt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 flex-align ">
              <div className="work-box">
                <div className="section-heading text-start">
                  <h2 className="heading-title">
                    {" "}
                    <b>
                      Empowering the Future of Decentralized{" "}
                      <span>Cross-Chain Finance</span>
                    </b>
                  </h2>
                  <a href="/login" className="btn">
                    Get PTL
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-6 text-center flex-align justify-center ">
              <div className="work-box">
                <img
                  src="images/get_proto.png"
                  className="rotation-img"
                  alt="Work Process"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" tip_sec pt-100">
        <div className="container">
          <div className="wip_card">
            <div className="row align-content-center">
              <div className="col-md-6">
                <div className="wip_content">
                  <h3>
                    What is <br /> <span>Proto</span>
                  </h3>
                  <p>
                    Proto is the native utility token that powers Protolink and
                    its rapidly growing ecosystem.
                  </p>
                </div>
              </div>

              <div className="col-md-5 text-center text-md-end">
                <img src="images/wip_img.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sm_sec sm_sec_in ">
        <div className="container">
          <div className="headt_wimg d-flex ">
            <h3 className="heading-title-sm">
              {" "}
              What is PROTO <br /> used for?{" "}
            </h3>
            <img
              src="images/getproto-arrow.svg"
              width="140"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="row g-3">
            <div className="col-lg-4">
              <div className="clint h-100">
                <div className="client_header">
                  <img
                    src="images/wpu_1.svg"
                    width="70"
                    alt=""
                    className="img-fluid"
                  />
                  <div>
                    <h2>Cross-Chain Transactions</h2>
                  </div>
                </div>
                <div className="clint-box">
                  <p>
                    PTL serves as the primary medium for seamless and secure
                    cross-chain transactions within the ProtoLink ecosystem.
                    Users can utilize PTL to transfer assets across various
                    blockchain networks with unparalleled efficiency and low
                    transaction costs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="clint h-100">
                <div className="client_header">
                  <img
                    src="images/wpu_2.svg"
                    width="70"
                    alt=""
                    className="img-fluid"
                  />
                  <div>
                    <h2>Governance and Decision-Making</h2>
                  </div>
                </div>
                <div className="clint-box">
                  <p>
                    PTL holders actively participate in the governance of the
                    ProtoLink protocol. By holding PTL, users gain voting rights
                    and influence over key decisions, ensuring a decentralized
                    and community-driven approach to the project's evolution.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="clint h-100">
                <div className="client_header">
                  <img
                    src="images/wpu_3.svg"
                    width="70"
                    alt=""
                    className="img-fluid"
                  />
                  <div>
                    <h2>Incentives and Rewards</h2>
                  </div>
                </div>
                <div className="clint-box">
                  <p>
                    PTL plays a pivotal role in incentivizing and rewarding
                    community engagement. Users holding and actively
                    participating in the ProtoLink ecosystem with PTL are
                    eligible for various incentives, contributing to the growth
                    and vitality of the overall network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cry_ser  ptb-100">
        <div className="container">
          <div className="section-heading text-center pb-65">
            <h2 className="heading-title">Get PTL </h2>
            <p className="heading-des">
              Engage in governing, staking, and interacting with the ProtoLink
              ecosystem
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="row g-2 justify-content-center roc_scroll_m">
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service1">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_1.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2> Exotic Markets </h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2  ">
                <a href="#/" className="single-service service2">
                  <div className="service-content">
                    <h2>Helix</h2>
                  </div>
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_2.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2   ">
                <a href="#/" className="single-service service3">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_3.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2>Hydro Protocol</h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service4">
                  <div className="service-content">
                    <h2>Mito</h2>
                  </div>
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_4.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service5">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_4.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2> Neptune </h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service1">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_1.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2> Exotic Markets </h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2  ">
                <a href="#/" className="single-service service2">
                  <div className="service-content">
                    <h2>Helix</h2>
                  </div>
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_2.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2   ">
                <a href="#/" className="single-service service3">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_3.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2>Hydro Protocol</h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service4">
                  <div className="service-content">
                    <h2>Mito</h2>
                  </div>
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_4.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service5">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_4.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2> Neptune </h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service1">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_1.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2> Exotic Markets </h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2  ">
                <a href="#/" className="single-service service2">
                  <div className="service-content">
                    <h2>Helix</h2>
                  </div>
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_2.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2   ">
                <a href="#/" className="single-service service3">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_3.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2>Hydro Protocol</h2>
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service4">
                  <div className="service-content">
                    <h2>Mito</h2>
                  </div>
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_4.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                </a>
              </div>
              <div className="col-6 col-md-4 col-xl-2 ">
                <a href="#/" className="single-service service5">
                  <div className="service-icon">
                    {" "}
                    <img
                      src="images/dpip_4.png"
                      width="60"
                      className="img-fluid"
                      alt=""
                    />{" "}
                  </div>
                  <div className="service-content">
                    <h2> Neptune </h2>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 
<!--         <div className="gpf_sec pt-100">
        <div className="container">
            <div className="section-heading text-start pb-65"> 
                <h2 className="heading-title">Get Proto with popular fiat currencies </h2>
                <p className="heading-des ms-0"> Select from various fiat on-ramp providers </p>
                
                <div className="row g-4" >
                    <div className="col-lg-6" >
                        <div className="fe_drop dropdown">
                            <select className="" >
                                <option>
                                    Payfura
                                </option>
                                <option>
                                    Transak
                                </option>
                            </select>
                          </div>

                          <img src="images/stak_img.svg" className="img-fluid d-none d-lg-block my-md-5" alt="" /> 
                    </div>
                    <div className="col-lg-6" >
                        <div className=" feat_card ratio ratio-16x9" >

                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
--> */}

      <div className="section-process ptb-100">
        <div className="container">
          <div className="w-layout-grid grid-process-section">
            <div className="process-content">
              <h3 className="h3-heading">
                {" "}
                Govern with <br /> PTL{" "}
              </h3>
              <p className="paragraph-large mb-3 text-primary">
                {" "}
                <b>
                  The global community directs ProtoLink's governance using its
                  native cryptocurrency, PTL.
                </b>{" "}
              </p>
              <p>
                Engage in the governance, staking, and interactive aspects of
                the ProtoLink ecosystem by acquiring PTL. As the native utility
                token, PTL offers users the opportunity to actively participate
                in shaping the project's future, contributing to the platform's
                decentralization and growth.
              </p>
            </div>
            <div>
              {/* <div className="w-layout-grid grid-process-stacked">
                        <div className="card-process align-content-center">
                            <img src="images/stake_1.svg" alt="" width="120" className="img-fluid" />
                            <div className="process-price">
                                $239M
                            </div>
                            <div className="heading-large"> Annual Gas Savings</div>
                              
                        </div>
                        <div className="card-process align-content-center">
                            <img src="images/stake_2.svg" alt="" width="120" className="img-fluid" />
                            <div className="process-price">
                                5,778,507
                            </div>
                            <div className="heading-large"> Total Proto Burned</div>
                              
                        </div>

                        
                    </div>
                    <div className="mt-2 " >
                        <a href="#/" className="btn btn-parimary"  > Stake  Proto </a>
                        <a href="#/" className=" btn-link ms-4 "  > Get Proto <i className="ms-2 ri-arrow-right-line"></i> </a>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetProto;
