import { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
function Wallet() {
  const SITE_KEY = "6LerNzopAAAAAM2Xvfff4UCzZNBxEQoUtBek3jHYMYqi";
  const navigate = useNavigate();
  const [reCaptchaValue, setRecaptchaValue] = useState("");
  const captchRef = useRef();
  const [formData, setFormData] = useState({
    wallet: "",
  });

  const { wallet } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const reCaptchHandler = (value) => {
    setRecaptchaValue(value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    captchRef.current.reset();

    if (formData.wallet.startsWith("0x") && formData.wallet.length === 42) {
      axios.defaults.withCredentials = true;
      await axios
        .post("https://ptl.p2psoftwares.com/api/v1//wallet", {
          ...formData,
          reCaptchaValue,
        })
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Wallet Address is  updated!");
            navigate("/dashboard");
          } else {
            navigate("/dashboard");
          }
        })
        .catch(
          (err) => toast.error(err.response.data.message),

          navigate("/Login")
        );

      setFormData({
        wallet: "",
      });
    } else {
      toast.error("Error:Wallet address incorrect");
    }
  };

  return (
    <form
      onSubmit={handleOnSubmit}
      className="mt-32 mb-10 mx-auto md:w-3/6 flex w-11/12 flex-col gap-y-4"
    >
      <label className="w-full">
        <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black">
          Wallet Address <sup className="text-pink-400">*</sup>
        </p>
        <input
          required
          type="text"
          name="wallet"
          value={wallet}
          onChange={handleOnChange}
          placeholder="Enter your wallet address"
          style={{
            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
          }}
          className="w-full rounded-[0.5rem]  p-[12px] text-black border bg-white border-[#0998F8]"
        />
      </label>
      <div>
        <ReCAPTCHA
          sitekey={SITE_KEY}
          onChange={reCaptchHandler}
          ref={captchRef}
        />
      </div>
      <button
        type="submit"
        className="mt-6 rounded-[8px] bg-yellow-200 py-[8px] px-[12px] font-medium text-black"
      >
        submit
      </button>
    </form>
  );
}

export default Wallet;
