import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../UI_Pages/Header";
import LandingPage from "../UI_Pages/LandingPage";
import ExploreEcosystem from "../UI_Pages/ExploreEcosystem";
import Footer from "../UI_Pages/Footer";
import Institution from "../UI_Pages/Institution";
import DeveloperOverview from "../UI_Pages/DeveloperOverview";
import GetProto from "../UI_Pages/GetProto";
import About from "../UI_Pages/About";
import Login from "../UI_Pages/Login";
import Dashboard from "../UI_Pages/Dashboard";
import ResetPassword from "../UI_Pages/ResetPassword";
import { TermCondition } from "../UI_Pages/TermCondition";
import PrivacyPolicy from "../UI_Pages/privacy_policy";
import Wallet from "../UI_Pages/wallet";
const Routing = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/*" element={<LandingPage />} />
          <Route path="/explore_ecyosystem" element={<ExploreEcosystem />} />
          <Route path="/institution_overview" element={<Institution />} />
          <Route path="/developer_overview" element={<DeveloperOverview />} />
          <Route path="/get_proto" element={<GetProto />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/term_condition" element={<TermCondition />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default Routing;
