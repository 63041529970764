import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ProjectSummery from "../components/ProjectSummery.jsx";
import Schedule from "../components/Schedule.jsx";
import Comparison from "../components/Comparison.jsx";
import Roadmap from "../components/Roadmap.jsx";
import Tokenomics from "../components/Tokenomics.jsx";
import toast from "react-hot-toast";
import Team from "../components/Team.jsx";
const Dashboard = () => {
  const [auth, setAuth] = useState(false);
  const [message, setMessage] = useState("");
  const [wallet, setWallet] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [accountType, setAccountType] = useState("ProjectSummery");
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  async function fetchData() {
    await axios
      .get("https://ptl.p2psoftwares.com/api/v1/")
      .then((res) => {
        setFirstName(res.data.message?.[0].firstName);
        setLastName(res.data.message?.[0].lastName);
        setEmailId(res.data.message?.[0].email);
        setWallet(res.data.message?.[0].wallet);

        if (res.data.success === true) {
          setAuth(true);
          navigate("/dashboard");
        } else {
          setAuth(false);
          setMessage(res.data.message);
          toast.error(res.data.message);
        }
      })
      .catch((err) => setMessage(err.response.data.message));
  }
  const handleDelete = () => {
    axios
      .get("https://ptl.p2psoftwares.com/api/v1/logout")
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchData();
  });
  return (
    <div className="pt-24  font-bold">
      {auth ? (
        <nav className="bg-[#712af9]    flex flex-col items-center justify-center text-center drop-shadow-sm">
          <div className="relative h-full  w-full">
            <img src="/images/cba_bg_img.png" alt="" className=" h-full" />
            <h3 className="sm:text-5xl text-2xl absolute top-1/2 z-10 w-full font-bold">
              ProtoLink Air Drop
            </h3>
          </div>
          <div className="sm:w-11/12 sm:mx-2 w-full  my-10 py-14 gap-7  bg-gradient-to-r from-[#FDCE39] to-[#ED5174]  rounded-2xl sm:p-2 p-4  text-white flex flex-col  justify-center items-center">
            <div className="w-full gap-2   flex sm:flex-row  flex-col  justify-between">
              <div className="flex items-center w-full  sm:justify-center gap-x-1 md:gap-x-3 ">
                <img
                  src={`https://api.dicebear.com/5.x/initials/svg?seed=${firstName} ${lastName}`}
                  alt="userImg"
                  width={60}
                  height={60}
                  className="rounded-full"
                />

                <span className="flex w-full flex-col justify-center gap-1">
                  <p className="inter text-black capitalize text-xl text-start font-medium">
                    {firstName} {lastName}
                  </p>
                  <p className="inter text-xs sm:text-lg text-start   text-black font-medium">
                    {emailId}
                  </p>
                  <p className="inter font-light text-start text-[8px]    sm:text-xs  text-black md:font-medium">
                    {wallet}
                  </p>
                  {/* <ArrowDown /> */}
                </span>
              </div>
              <div className="flex flex-col items-center ">
                {" "}
                <div
                  className="my-2 py-2 sm:py-0  px-4 flex  justify-center items-center rounded-md text-black font-medium sm:font-normal hover:bg-[#0B98E5] bg-[#0B96F6]"
                  onClick={handleDelete}
                >
                  <button>Logout</button>
                </div>
                <div className="inter text-xs sm:text-lg text-center  text-black font-medium">
                  {" "}
                  <Link to="/wallet">
                    <p className="mt-1 ml-auto max-w-max text-lg text-black">
                      Update Your Wallet Address
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-between">
              <div className="flex flex-col gap-2  justify-center text-xl font-medium text-black">
                <p> AirDrop Total Received: </p>
                <h2>1 PTL</h2>
              </div>
              <img src="/images/fav-icon.png" alt="" />
            </div>
            <div className="w-full  flex sm:flex-row flex-col gap-3 justify-between text-xl font-medium text-black ">
              <h2>Total Supply:21K</h2>
              <h3>User Air Drop:1 PTL($400)</h3>
            </div>
          </div>
          <div className="my-5 sm:w-11/12  mx-2 gap-y-10 w-full flex flex-col md:flex-row justify-between">
            <div className="md:w-[48%] m-2 sm:m-0 hover:bg-[#5e25c9] bg-gradient-to-r from-[#5e25c4] to-[#5e25f5] transition-all duration-150 rounded-xl p-4">
              <p className="text-2xl  text-start  ">Reign Of Terror</p>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Token Distribution</h2>
                <p>10 BUSD</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Min. Allocation</h2>
                <p>0.03 BUSD</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Max. Allocation</h2>
                <p>2351.03 BUSD</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Token Price</h2>
                <p>1 BUSD = 325.35 SIDUS</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Access Type</h2>
                <p>Private</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Token Distribution</h2>
                <p>10 BUSD</p>
              </div>
            </div>
            <div className="md:w-[48%] m-2 sm:m-0 hover:bg-[#5e25c9] bg-gradient-to-r from-[#5e25c4] to-[#5e25f5] transition-all duration-150 rounded-xl p-4">
              <p className="text-2xl  text-start  ">Token Info</p>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Token Name</h2>
                <p>Protolink</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Token Symbol</h2>
                <p>TBT</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Decimals</h2>
                <p>23.03 </p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Address</h2>
                <p>22cf3459gf3d009</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Access Type</h2>
                <p>Private</p>
              </div>
              <div className="my-3 text-lg items-center text-[#beb8cc] flex justify-between">
                <h2>Total Sypply</h2>
                <p>235,342,889.00 TBT</p>
              </div>
            </div>
          </div>
          <div className="sm:w-11/12 w-full px-1">
            {" "}
            <div className="w-full md:rounded-full flex flex-wrap  justify-between lg:border text-white my-4 overflow-hidden">
              <button
                className={`${
                  accountType === "ProjectSummery"
                    ? "bg-[#0B96F6] "
                    : "bg-transparent "
                } py-3 px-7 text-sm  sm:text-base
              rounded-full transition-all duration-200`}
                onClick={() => {
                  setAccountType("ProjectSummery");
                }}
              >
                PROJECT SUMMERY
              </button>
              <button
                className={`${
                  accountType === "schedule"
                    ? "bg-[#0B96F6] "
                    : "bg-transparent "
                } py-3 px-7 text-sm  sm:text-base
              rounded-full transition-all duration-200`}
                onClick={() => {
                  setAccountType("schedule");
                }}
              >
                SCHEDULE
              </button>

              <button
                className={`${
                  accountType === "comparison"
                    ? "bg-[#0B96F6]"
                    : "bg-transparent "
                } py-3 px-6 rounded-full transition-all duration-200`}
                onClick={() => {
                  setAccountType("comparison");
                }}
              >
                COMPARISON
              </button>
              <button
                className={`${
                  accountType === "tokenomics"
                    ? "bg-[#0B96F6] "
                    : "bg-transparent "
                } py-3 px-6 rounded-full transition-all duration-200`}
                onClick={() => {
                  setAccountType("tokenomics");
                }}
              >
                TOKENOMICS
              </button>
              <button
                className={`${
                  accountType === "roadmap"
                    ? "bg-[#0B96F6] "
                    : "bg-transparent "
                } py-3 px-6 rounded-full transition-all duration-200`}
                onClick={() => {
                  setAccountType("roadmap");
                }}
              >
                ROADMAP
              </button>
              <button
                className={`${
                  accountType === "team" ? "bg-[#0B96F6]" : "bg-transparent "
                } py-3 px-6 rounded-full transition-all duration-200`}
                onClick={() => {
                  setAccountType("team");
                }}
              >
                TEAM
              </button>
            </div>
            {accountType === "ProjectSummery" && <ProjectSummery />}
            {accountType === "schedule" && <Schedule />}
            {accountType === "comparison" && <Comparison />}
            {accountType === "tokenomics" && <Tokenomics />}
            {accountType === "roadmap" && <Roadmap />}
            {accountType === "team" && <Team />}
          </div>
        </nav>
      ) : (
        <div className=" flex flex-col gap-3 w-9/12 md:w-1/3 mx-auto  justify-center ">
          <p className="text-xl my-7 text-center font-medium text-red-600">
            PLEASE login first, {message}
          </p>
          <div className="my-2 py-2 sm:py-0  px-4 flex justify-center items-center rounded-md text-black font-medium sm:font-normal hover:bg-[#0B98E5] bg-[#0B96F6]">
            <Link to="/login">
              {" "}
              <button>Login</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
