import React from "react";

const privacy_policy = () => {
  const cookieItem = [
    {
      id: "1.",
      title: "Essential Cookies:",

      subText: [
        "Vital for providing requested Services through our Site; without these, the requested Services cannot be delivered.",
      ],
    },
    {
      id: "2.",
      title: "Functionality Cookies:",

      subText: [
        "Enable our Services to remember your preferences and choices, such as your connected Wallet address, offering a personalized experience.",
      ],
    },
    {
      id: "3.",
      title: "Analytics and Performance Cookies:",

      subText: [
        "Collect information on Site traffic and user interaction without identifying individual visitors. This data enhances the efficiency of our Site and Services.",
        "Google Analytics: Employed for improving Site and Services functionality. Google Analytics utilizes its cookies for this purpose.",
      ],
    },
  ];
  const kirgizstanItem = [
    {
      id: "1.",
      title: "Contractual Necessity:",

      subText: [
        "Processing necessary to fulfill the contract either entered into or about to be entered into concerning the Services.",
      ],
    },
    {
      id: "2.",
      title: "Legitimate Interests:",

      subText: [
        "Processing that serves our legitimate interests or those of a third party, provided such interests do not override your fundamental rights and interests.",
      ],
    },
    {
      id: "3.",
      title: "Legal and Regulatory Obligations:",

      subText: [
        "Processing mandated to comply with legal or regulatory obligations within KIRGIZSTAN.",
      ],
    },
  ];
  const sharingcollectionItem = [
    {
      id: "1.",
      title: "Express Consent:",

      subText: ["Sharing personal information with your explicit consent."],
    },
    {
      id: "2.",
      title: "Legal and Safety Obligations:",

      subText: [
        "Sharing information for legal, protection, compliance, and safety purposes.",
        "Complying with applicable laws.",
        "Responding to lawful requests, subpoenas, and legal processes from law enforcement, officials, and regulatory agencies.",
      ],
    },
    {
      id: "3.",
      title: "Rights and Property Protection:",

      subText: [
        "Safeguarding the rights and property of ProtoLink, its agents, customers, and others.",
        "Enforcing our Terms, agreements, or other policies.",
      ],
    },
    {
      id: "4.",
      title: "Emergency Situations:",

      subText: [
        "Sharing information in emergencies to ensure the safety of our employees, agents, customers, or any individual.",
      ],
    },
    {
      id: "5.",
      title: "Blockchain Network Sharing:",

      subText: [
        "Automatically sharing information over a publicly available blockchain network through specific Services, including the Wallet. Note that such information may be challenging or impossible to erase, as we may lack control over it.",
      ],
    },
  ];

  const usecollectionItem = [
    {
      id: "1.",
      title: "Operational Purposes:",

      subText: [
        "Utilizing your personal information to operate, maintain, and enhance the Services and other associated products.",
        "Providing customer support and addressing inquiries related to the Services.",
      ],
    },
    {
      id: "2.",
      title: "Security Measures:",

      subText: [
        "Protecting against, investigating, and deterring fraudulent, unauthorized, or illegal activities.",
        "Making reasonable efforts to contact users before complying with a subpoena seeking information about them or a limited group of users. Note that we cannot guarantee contact in all cases due to various reasons.",
      ],
    },
    {
      id: "3.",
      title: "Risk Assessment:",

      subText: [
        "Conducting security investigations and risk assessments to ensure the integrity of the Services.",
      ],
    },
    {
      id: "4.",
      title: "Aggregated Data Analysis:",

      subText: [
        "Using collected information to compile aggregated data, enabling us to gain insights into user behavior and enhance overall user experience.",
      ],
    },
    {
      id: "5.",
      title: "Limited Disclosure:",

      subText: [
        "Except as explicitly stated, ProtoLink refrains from selling, renting, or otherwise disclosing personal information to third parties.",
      ],
    },
  ];
  const collectionItem = [
    {
      id: "1.",
      title: "Information You Give Us: ",
      text: "This includes data such as your name, email address, and phone number that you provide by filling in forms on our Site or through our Services, or when corresponding with us via phone, email, or other means.",
      subText: [],
    },
    {
      id: "2.",
      title: "Information We Collect About You:  ",
      text: "For each visit to our Site or use of our Services, we automatically collect:",
      subText: [
        "IP Information: Technical details about your Service use, such as browser type and version, the last access time of your Wallet, and Internet Protocol (IP) addresses used to access the Services.",
        "Device and Analytical Information: Aggregate information and analytical data related to your Services usage, which is non-personally identifiable or anonymous.",
        "Publicly-available blockchain data: When connecting your non-custodial blockchain wallet to the Services, we collect and log your publicly-available blockchain address to understand your Services usage better and screen your wallet for any prior illicit activity. Note that blockchain addresses are publicly-available data, not created or assigned by us, and are not personally identifying.",
      ],
    },
    {
      id: "3.",
      title: "Information We Collect from Other Sources: ",
      text: "We may receive information from external sources and combine it with data collected through our Services, such as:",
      subText: [
        "Blockchain Data: Information associated with your Wallet from publicly available data or blockchain intelligence analytics providers on a blockchain network. This includes data collected from using blockchain explorers to fulfill our obligations and prevent the use of our Services in connection with fraudulent or illicit activities.",
      ],
    },
  ];
  return (
    <section className="mb-16 pt-24">
      <div className="max-w-6xl m-auto text-sm text-lightGrey px-3">
        <p className="mb-3">
          This Privacy Policy (the “Policy”) outlines how Protolink Ltd.
          (“ProtoLink,” the “Company,” “we,” “us,” or “our”) gathers, utilizes,
          and shares data in relation to the ProtoLink (ProtoLink.io) web app,
          as well as all our other properties, products, and services (the
          “Services”). Your use of the Services is subject to this Policy and
          our Terms of Service.
        </p>
        <h4 className="text-xl underline font-semibold mb-3">NOTICE</h4>
        <p className="mb-3">
          We respect your privacy and are dedicated to safeguarding personal
          information provided in connection with your use of the Services. This
          Privacy Policy aims to inform you about the Company’s information
          practices, covering the types of information collected, how it is used
          and protected, and how you can control the maintenance and sharing of
          your information.
        </p>
        <p className="mb-3 italic">
          BY USING THE SERVICES, YOU CONSENT TO THE TERMS AND CONDITIONS OF THIS
          PRIVACY POLICY AND TO OUR PROCESSING OF PERSONAL INFORMATION FOR THE
          PURPOSES STATED BELOW. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS
          OF THIS PRIVACY POLICY, PLEASE DO NOT USE THE SERVICES.
        </p>
        <h2 className="text-xl underline font-semibold mb-3">
          {" "}
          COLLECTION OF PERSONAL INFORMATION AND OTHER DATA
        </h2>
        <p className="mb-10">
          When you access or use the Services, we collect the following
          information:
        </p>

        <div className=" mb-5">
          <ul className="mb-1 ml-4 ">
            {collectionItem.map((item) => (
              <li key={item} className="my-3  ml-2">
                <span className="  text-xl font-semibold">
                  {item.id} {item.title}
                </span>{" "}
                {item.text}
                {item.subText.map((list) => (
                  <li className="list-disc ml-7 my-1">{list}</li>
                ))}
              </li>
            ))}
          </ul>
          <h4 className="text-xl underline font-semibold mb-2">
            USE OF INFORMATION COLLECTED
          </h4>
          <p>
            We outline below the various ways we intend to use your personal
            information, relying primarily on the legal basis of legitimate
            interest unless otherwise specified:
          </p>
          <ul className="mb-1 ml-4 ">
            {usecollectionItem.map((item) => (
              <li key={item} className="my-3  ml-2">
                <span className="  text-xl font-semibold">
                  {item.id} {item.title}
                </span>{" "}
                {item.subText.map((list) => (
                  <li className="list-disc ml-7 my-2">{list}</li>
                ))}
              </li>
            ))}
          </ul>
          <h4 className="text-xl underline font-semibold mb-2">
            SHARING OF PERSONAL INFORMATION
          </h4>
          <p>
            We may share personal information under the following circumstances:
          </p>
          <ul className="mb-1 ml-4 ">
            {sharingcollectionItem.map((item) => (
              <li key={item} className="my-3  ml-2">
                <span className="  text-xl font-semibold">
                  {item.id} {item.title}
                </span>{" "}
                {item.subText.map((list) => (
                  <li className="list-disc ml-7 my-2">{list}</li>
                ))}
              </li>
            ))}
          </ul>
          <h4 className="text-xl underline font-semibold mb-2">
            LEGAL BASIS FOR PROCESSING IN KIRGIZSTAN
          </h4>
          <p>
            In KIRGIZSTAN, the processing of your personal data by ProtoLink is
            grounded on the following legal foundations:
          </p>
          <ul className="mb-1 ml-4 ">
            {kirgizstanItem.map((item) => (
              <li key={item} className="my-3  ml-2">
                <span className="  text-xl font-semibold">
                  {item.id} {item.title}
                </span>{" "}
                {item.subText.map((list) => (
                  <li className="list-disc ml-7 my-2">{list}</li>
                ))}
              </li>
            ))}
          </ul>
          <p className="mb-5">
            For specific details on the legal basis used for processing your
            personal data, especially in cases where multiple legal grounds are
            applicable, please contact{" "}
            <span className="text-[#0B96F6] font-medium text-lg">
              {" "}
              privacy@protolink.io.
            </span>{" "}
            ProtoLink assumes the role of the responsible data controller in
            accordance with KIRGIZSTAN data protection laws.
          </p>
          <h4 className="text-xl underline font-semibold mb-2">
            COOKIES WE USE
          </h4>
          <p>
            Our Site employs various types of cookies with distinct purposes:
          </p>
          <ul className="mb-1 ml-4 ">
            {cookieItem.map((item) => (
              <li key={item} className="my-3  ml-2">
                <span className="  text-xl font-semibold">
                  {item.id} {item.title}
                </span>{" "}
                {item.subText.map((list) => (
                  <li className="list-disc ml-7 my-2">{list}</li>
                ))}
              </li>
            ))}
          </ul>
          <div className="my-4">
            {" "}
            <h4 className="text-xl underline font-semibold mb-2">
              DISABLING COOKIES
            </h4>
            <p>
              You can manage cookies on our Site through your browser settings.
              To remove or reject cookies, follow your browser's instructions,
              usually found within the "settings," "help," "tools," or "edit"
              sections. Most browsers default to accepting cookies, and altering
              settings may impact Site functionality.
            </p>
          </div>
          <div className="my-4">
            {" "}
            <h4 className="text-xl underline font-semibold mb-2">SECURITY</h4>
            <p>
              We employ reasonable administrative, physical, and technical
              security measures to safeguard data from potential risks such as
              loss, theft, unauthorized access, disclosure, alteration, and
              destruction. Despite these precautions, complete security over the
              internet cannot be guaranteed. The responsibility for all
              activities on the Services, including the security of blockchain
              network addresses, cryptocurrency wallets, and their cryptographic
              keys, rests with you.
            </p>
          </div>
          <div className="my-4">
            {" "}
            <h4 className="text-xl underline font-semibold mb-2">
              DATA STORAGE AND TRANSFER
            </h4>
            <p>
              Information collected through our Services may be stored and
              processed in Singapore or other countries where ProtoLink, its
              affiliates, or service providers have facilities. If you are in
              Kirgizstan or other regions with differing data protection laws,
              note that we may transfer information, including personal data, to
              a country without the same data protection laws as your
              jurisdiction. We take all reasonable steps to ensure that personal
              data is treated securely and in accordance with this Privacy
              Policy. Such transfers adhere to appropriate safeguards. For more
              information on these transfers, contact us using the details
              provided at the end of this Privacy Policy. In Kirgizstan,
              personal data is stored for the duration required to fulfill the
              data collection purposes, unless otherwise mandated by law.
            </p>
          </div>
          <div className="my-4">
            {" "}
            <h4 className="text-xl font-semibold underline  mb-2">
              SPECIAL NOTICE REGARDING MINORS UNDER 18 YEARS OF AGE
            </h4>
            <p>
              The Services are exclusively intended for users aged 18 and older.
              By using the Services, you confirm that you are at least 18 years
              old. We do not knowingly collect personal information from
              individuals under 18. If you believe that a child under 18 has
              provided information to us, please inform us at
              <span className="text-[#0B96F6] font-medium text-lg">
                {" "}
                privacy@protolink.io.
              </span>{" "}
              If unintentionally collected, information on children under 18
              will be promptly deleted upon notification. We are not liable for
              damages resulting from misrepresentation of age by users.
            </p>
            <p className="my-2">
              Requests for information, access, and deletion will be responded
              to insofar as we can reasonably associate the provided identifying
              details with the information maintained. Users are entitled to
              exercise these rights without facing discrimination.
            </p>
            <p className="my-2">
              <span className="font-bold text-lg ">Submitting a Request: </span>{" "}
              You can submit requests for information, access, or deletion to
              <span className="text-[#0B96F6] font-medium text-lg">
                {" "}
                privacy@protolink.io.
              </span>{" "}
            </p>
            <div className="my-4">
              {" "}
              <h4 className="text-xl font-semibold underline mb-2">
                GOVERNING LAW NOTIFICATION OF CHANGES
              </h4>
              <p>
                This Privacy Policy is governed by the laws of Singapore,
                without giving effect to any conflict of law principles.
                Disputes under this Privacy Policy are subject to the terms of
                the dispute resolution outlined in our Terms. Each use of our
                Services will be governed by the current version of the Privacy
                Policy. Therefore, when utilizing our Services, it's advisable
                to check the date of this Privacy Policy (located at the top)
                and review any changes since the last version. As our business
                evolves, this Privacy Policy may be subject to periodic changes.
                Unless explicitly stated otherwise, the current Privacy Policy
                applies to all information we have about you. We commit not to
                significantly alter our policies and practices to weaken the
                protection of customer information collected in the past without
                the consent of affected customers.
              </p>
            </div>
            <div className="my-4">
              {" "}
              <h4 className="text-xl underline font-semibold mb-2">
                CONTACT US
              </h4>
              <p className="my-2">
                For questions or comments about this Privacy Policy or the
                information practices of the Services, please reach out to us
                via email at
                <span className="text-[#0B96F6] font-medium text-lg">
                  {" "}
                  privacy@protolink.io.
                </span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default privacy_policy;
