import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

const LandingPage = () => {
  return (
    <div className="home_wrapper">
      <section className="home-banner" id="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 position-u flex-align ">
              <div className="banner-contain">
                <h1 className="banner-heading">
                  Welcome to the world of cross-chain transfers with{" "}
                  <span>ProtoLink</span>{" "}
                </h1>
                <p className="banner-des">
                  {" "}
                  PTL: The World's First Secure, Fast, and Low-Cost Solution for
                  All Your Crypto Cross-Chain Transfers!{" "}
                </p>
                <a href="/get_proto" className="btn">
                  Get PTL{" "}
                </a>
                <a href="/explore_ecyosystem" className=" btn-link px-4  ">
                  PTL Ecosystem <i className="ms-1 ri-arrow-right-up-line"></i>{" "}
                </a>
                <ul className="bopoc_sec">
                  <li>
                    <strong>8,144,588</strong>
                    Block Height
                  </li>
                  <li>
                    <strong> {"<3seconds"} </strong>
                    Average block time
                  </li>
                  <li>
                    <strong> 3,79,740 </strong>
                    Total transactions
                  </li>
                  <li>
                    <strong> {"<$0.001"} </strong>
                    Average transaction cost
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 position-r flex justify-center  ">
              <div className="  hero_img w-3/6 sm:w-8/12 md:w-11/12 xl:w-full">
                <img alt="" src="images/about-2.svg" className="globe_logo" />
                <img
                  alt=""
                  src="images/about-2a.svg"
                  className="globe_ring rrotate_1"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="part_card">
          <div className="container-fluid px-0">
            <p className="text-center mb-4 text-primary">
              Connecting you together with smart contracts, projects and
              blockchains{" "}
            </p>
            <div className="owl-carousel owl-carousel-partner owl-theme">
              <div className="item">
                <div className="part_img">
                  <img alt="" src="images/part_1.png" className="img-fluid" />
                </div>
              </div>
              <div className="item">
                <div className="part_img">
                  <img alt="" src="images/part_2.png" className="img-fluid" />
                </div>
              </div>
              <div className="item">
                <div className="part_img">
                  <img alt="" src="images/part_3.png" className="img-fluid" />
                </div>
              </div>
              <div className="item">
                <div className="part_img">
                  <img alt="" src="images/part_4.png" className="img-fluid" />
                </div>
              </div>
              <div className="item">
                <div className="part_img">
                  <img alt="" src="images/part_5.png" className="img-fluid" />
                </div>
              </div>
              <div className="item">
                <div className="part_img">
                  <img alt="" src="images/part_6.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" cba_sec   ptb-100">
        <img
          alt=""
          src="images/about_pre_img.svg"
          className="img-fluid about_pre_img  "
        />
        <div className="container">
          <div className="row">
            <div className="col-md-6  ">
              <div className="work-box">
                <h2>
                  Leading the Way in Financial <span>App Development</span>
                </h2>

                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Transforming Smart Contracts{" "}
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    {" "}
                    Innovating Interoperability{" "}
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    {" "}
                    Tailored DeFi Solutions{" "}
                  </button>
                  <button
                    className="nav-link"
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                  >
                    Unparalleled Security
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 flex-align">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane   show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <div className="cba_content">
                    <img
                      alt=""
                      src="images/cba_img.svg"
                      className="img img-fluid  mb-5"
                    />
                    <p className="">
                      Launch dynamic dApps with PortoLink's Cross-Chain Smart
                      Contract Protocol. PortoLink Leads the Way in Introducing
                      Auto-Executing Smart Contracts, Enabling Faster, Cost
                      efficient, More Innovative, more secure and Advance
                      Applications."
                    </p>
                    <a
                      href="/developer_overview"
                      className=" btn-link mt-4 d-block   "
                    >
                      {" "}
                      Start Building{" "}
                      <i className="ms-1 ri-arrow-right-up-line"></i>{" "}
                    </a>
                  </div>
                </div>
                <div
                  className="tab-pane  "
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  <div className="cba_content">
                    <img
                      alt=""
                      src="images/cba_img.svg"
                      className="img img-fluid   mb-5"
                    />
                    <p className="">
                      Effortlessly transfer your crypto assets and Web3 data
                      from leading blockchains to leverage on any application
                      built on PortoLink. All dApps within PortoLink's ecosystem
                      seamlessly access sovereign layer one chains, including
                      Ethereum, Binance Smart Chain, Polygon, Tron, Solana, and
                      more, for rapid, cost-effective, and seamless
                      interoperable communication.
                    </p>
                    <a
                      href="/developer_overview"
                      className=" btn-link mt-4 d-block   "
                    >
                      {" "}
                      Start Building{" "}
                      <i className="ms-1 ri-arrow-right-up-line"></i>{" "}
                    </a>
                  </div>
                </div>
                <div
                  className="tab-pane  "
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  <div className="cba_content">
                    <img
                      alt=""
                      src="images/cba_img.svg"
                      className="img img-fluid   mb-5"
                    />
                    <p className="">
                      Tailored DeFi solutions empower users to take control of
                      their financial destiny in the DeFi space. They enable
                      customization, flexibility, and the ability to create DeFi
                      strategies that match specific goals and risk profiles. As
                      DeFi continues to evolve, these tailored solutions provide
                      a pathway for individuals and businesses to leverage the
                      full potential of decentralized finance.
                    </p>
                    <a
                      href="/developer_overview"
                      className=" btn-link mt-4 d-block   "
                    >
                      {" "}
                      Start Building{" "}
                      <i className="ms-1 ri-arrow-right-up-line"></i>{" "}
                    </a>
                  </div>
                </div>
                <div
                  className="tab-pane  "
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                >
                  <div className="cba_content">
                    <img
                      alt=""
                      src="images/cba_img.svg"
                      className="img img-fluid  mb-5"
                    />
                    <p className="">
                      Deploy applications confidently on PortoLink, backed by a
                      globally distributed network of validator nodes, with
                      major institutions participating in the proof-of-stake
                      network. PortoLink utilizes the Tendermint PoS consensus
                      for transaction security, providing instant finality.
                    </p>
                    <a
                      href="/developer_overview"
                      className=" btn-link mt-4 d-block   "
                    >
                      {" "}
                      Start Building{" "}
                      <i className="ms-1 ri-arrow-right-up-line"></i>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq-part pt-100">
        <img alt="" src="images/pre_img_2.svg" className="img-fluid pre_img2" />
        <div className="container">
          <div className="row section-heading  pb-65 align-items-center">
            <div className="col-md-6">
              <div className=" ">
                <h2 className="heading-title">
                  Discover <span>PortoLink's</span> Infinite Possibilities{" "}
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <p>
                PortoLink stands as a secure and decentralized cross-chain
                protocol, bridging various blockchain networks. PortoLink
                ecosystem is a gateway to the future of decentralized finance
                and blockchain interoperability.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12  ">
              <ul className="nav nav-tab Frequently-tabs mb-55">
                <li>
                  <a className="tab-link active" href="#/" data-tab="tab-1">
                    {" "}
                    <i className="ri-arrow-right-s-line me-1"></i> Featured
                    dApps
                  </a>
                </li>
                <li>
                  <a className="tab-link" href="#/" data-tab="tab-2">
                    {" "}
                    <i className="ri-arrow-right-s-line me-1"></i> Institutions
                  </a>
                </li>
                <li>
                  <a className="tab-link" href="#/" data-tab="tab-3">
                    {" "}
                    <i className="ri-arrow-right-s-line me-1"></i> Blockchains
                  </a>
                </li>
                <li>
                  <a className="tab-link" href="#/" data-tab="tab-4">
                    {" "}
                    <i className="ri-arrow-right-s-line me-1"></i> Exchanges
                  </a>
                </li>
                <li>
                  <a className="tab-link" href="#/" data-tab="tab-5">
                    {" "}
                    <i className="ri-arrow-right-s-line me-1"></i> Wallets
                  </a>
                </li>
                {/* <li>
                 <span onClick={()=>console.log('check')}><a className="tab-link" href="/explore_ecyosystem"  > <i className="ri-arrow-right-s-line me-1"></i>  View all</a></span> 
                </li> */}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12  ">
              <div className="tab-content">
                <div className="tab-panel active" id="tab-1">
                  <div className="row g-2 justify-content-center roc_scroll_m">
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service1">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/fav-icon.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2> PTL wallet </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2  ">
                      <a href="#/" className="single-service service2">
                        <div className="service-content">
                          <h2>R wallet </h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/dpip_2.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tab-panel active" id="tab-2">
                  <div className="row g-2 justify-content-center roc_scroll_m"></div>
                </div>

                <div className="tab-panel" id="tab-3">
                  <div className="row g-2 justify-content-center roc_scroll_m">
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service1">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/etherium.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2> Ethereum </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2  ">
                      <a href="#/" className="single-service service2">
                        <div className="service-content">
                          <h2>Binance Smart Chain (BSC)</h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/binance.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2   ">
                      <a href="#/" className="single-service service3">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/polygon.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2>Polygon</h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service4">
                        <div className="service-content">
                          <h2> Solana</h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/solana.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service5">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/tron.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2> Tron </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service view_all">
                        <div className="service-content">
                          <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                          <h2> R-Wallet </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tab-panel" id="tab-4">
                  <div className="row g-2 justify-content-center roc_scroll_m">
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service1">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/binance.png"
                            width="60"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2> Binance </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2  ">
                      <a href="#/" className="single-service service2 p-4 ">
                        <div className="service-content">
                          <h2>Mexc</h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/mexc.svg"
                            width="100"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2   ">
                      <a href="#/" className="single-service service3">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/taxbits.png"
                            width="45"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2>Taxbits</h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service4">
                        <div className="service-content">
                          <h2>KuCoin</h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/kucoin.png"
                            width="40"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service5">
                        <div className="service-content">
                          <h2>Uniswap</h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/uniswap.png"
                            width="40"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service5">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/pancake.png"
                            width="35"
                            height="20"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2> Pancake swap</h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service5">
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/binance.png"
                            width="40"
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div className="service-content">
                          <h2> Binance dex </h2>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="tab-panel" id="tab-5">
                  <div className="row g-2 justify-content-center roc_scroll_m">
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service service1 p-4">
                        <div className="service-content view_all">
                          <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                          <h2> Ledger Nano S/X </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2  ">
                      <a href="#/" className="single-service service2 p-4">
                        <div className="service-content  view_all">
                          <h2>Trezor</h2>
                        </div>
                        <div className="service-icon ">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/trezor.png"
                            width="90"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2   ">
                      <a href="#/" className="single-service view_all service3">
                        <div className="service-content service3">
                          <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                          <h2>Metamask</h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service view_all service4">
                        <div className="service-content">
                          <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                          <h2> Trust Wallet </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service view_all service5">
                        <div className="service-content">
                          <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                          <h2> R-Wallet </h2>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service view_all servic4">
                        <div className="service-content">
                          <h2>MyEtherWallet (MEW)</h2>
                        </div>
                        <div className="service-icon">
                          {" "}
                          <img
                            alt=""
                            src="images/landingPage/myetherwallet.png"
                            width="33"
                            className="img-fluid"
                          />{" "}
                        </div>
                      </a>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2 ">
                      <a href="#/" className="single-service view_all">
                        <div className="service-content">
                          <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                          <h2> Safepal</h2>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sm_sec">
        <div className="container-xxl">
          <div className="sm_card">
            <div className=" ">
              <h2 className="heading-title">
                {" "}
                Featured <br />
                <span className=""> dApps </span>{" "}
              </h2>
              <p>
                Move your assets between diverse blockchain networks securely
                and efficiently.{" "}
              </p>
            </div>
            <div className=" program_swiper sm_owl owl-theme">
              <Swiper
                spaceBetween={50}
                slidesPerView={2}
                autoplay={{
                  delay: 2000,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <div className="item" role="group" aria-label="1 / 5">
                    <div className="clint">
                      <div className="client_header">
                        <img
                          alt=""
                          src="images/heliex_img.png"
                          width="70"
                          className="img-fluid"
                        />
                        <div>
                          <h2>R Wallet</h2>
                          {/* <span className="badge badge-primary" >Orderbook Exchange</span> */}
                        </div>
                      </div>
                      <div className="clint-box">
                        <p>
                          <strong>Description</strong>
                          R-Wallet is a decentralized, peer-to-peer (P2P)
                          digital asset and payment network supported by an
                          open-source blockchain protocol that enables instant,
                          near-zero cost transfer to anyone in the world.
                          R-Wallet stands out by offering a comprehensive
                          breakdown of token transactions, allowing users to
                          track and analyze their investments and financial
                          activities with ease.
                        </p>

                        <p>
                          <strong>Utilize </strong>
                          dApp, Crypto wallet
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="item" role="group" aria-label="1 / 5">
                    <div className="clint">
                      <div className="client_header">
                        <img
                          alt=""
                          src="images/heliex_img.png"
                          width="70"
                          className="img-fluid"
                        />
                        <div>
                          <h2>PTL Wallet</h2>
                          {/* <span className="badge badge-primary" >Orderbook Exchange</span> */}
                        </div>
                      </div>
                      <div className="clint-box">
                        <p>
                          <strong>Description</strong>
                          Effortlessly move your crypto across different
                          blockchains, PortoLink (PTL) wallet is your key to the
                          world of secure and efficient cross-chain asset
                          management. It's not just a wallet; it's your gateway
                          to a seamless crypto experience. With PortoLink's PTL
                          wallet, you can securely store, send, receive, and
                          manage your crypto assets from a diverse range of
                          blockchain networks.
                        </p>
                        <p>
                          <strong>Utilize </strong>
                          dApp, Crypto wallet
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
