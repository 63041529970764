import React, { useState } from "react";
import LoginForm from "../components/Login";
import RegisterForm from "../components/Sign";
import Tab from "../components/Tab";
const Login = () => {
  const [formType, setFormType] = useState("register");
  const tabData = [
    {
      id: 1,
      tabName: "Login",
      type: "login",
    },
    {
      id: 2,
      tabName: "Sign up",
      type: "register",
    },
  ];
  return (
    <div className="pt-24   bg-[#0998F8] pb-6 flex justify-center items-center max-md:px-2">
      <div className="bg-white flex flex-col items-center  rounded max-w-2xl w-full px-6 pt-5 pb-8">
        {" "}
        <Tab tabData={tabData} field={formType} setField={setFormType} />
        <div className="w-full">
          {formType === "register" && <RegisterForm />}
          {formType === "login" && <LoginForm />}
        </div>
      </div>
    </div>
  );
};

export default Login;
