import React from "react";

const ProjectSummery = () => {
  const projectListItem = [
    "A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.",
    "A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.",
    "A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership assets from various metaverse and gaming projects.",
    "A gamified marketplace dedicated to the metaverse and gaming assets. The Metaverse marketplace allows users to trade, auction, and rent virtual ownership.",
  ];
  return (
    <section className="mb-16">
      <div className="max-w-6xl  m-auto text-sm text-lightGrey  px-2 sm:px-3">
        <img src="/images/project-9.jpg" alt="" className="w-full my-4" />

        <div className=" mb-5">
          <h3 className="text-2xl text-start font-semibold text-yellow-400 mb-6  ">
            Project Summary
          </h3>
          <ul className="my-2 sm:pl-4  flex flex-col items-start  ">
            {projectListItem.map((item, i) => (
              <li
                key={i}
                className="my-2 text-xl font-normal sm:list-disc text-yellow-400 text-start"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className=" my-5">
          <h3 className="text-2xl text-start font-semibold text-yellow-400 mb-6  ">
            Schedule
          </h3>
          <ul className="my-2 sm:pl-4  flex flex-col items-start  ">
            {projectListItem.map((item, i) => (
              <li
                key={i}
                className="my-2 text-xl font-normal sm:list-disc text-yellow-400 text-start"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className=" mb-5">
          <h3 className="text-2xl text-start font-semibold text-yellow-400 mb-6  ">
            Comparison
          </h3>
          <ul className="my-2 sm:pl-4  flex flex-col items-start  ">
            {projectListItem.map((item, i) => (
              <li
                key={i}
                className="my-2 text-xl font-normal sm:list-disc text-yellow-400 text-start"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ProjectSummery;
