function validation(values) {
  console.log(values);
  let error = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}$/;
  if (values.firstName === "") {
    error.firstName = "Please Enter your name.";
  } else {
    error.firstName = "";
  }
  if (values.email === "") {
    error.email = "Please Enter your email";
  } else if (!email_pattern.test(values.email)) {
    error.email = "Email Didn't match";
  } else {
    error.email = "";
  }

  if (values.password === "") {
    error.password = "Password should not be empty.";
  } else if (!password_pattern.test(values.password)) {
    error.password = "Password is too easy.";
  } else {
    error.password = "";
  }

  if (values.confirmPassword === "") {
    error.confirmPassword = "Password should not be empty.";
  } else if (!password_pattern.test(values.confirmPassword)) {
    error.confirmPassword = "Password is too easy. ";
  } else {
    error.confirmPassword = "";
  }
  return error;
}
export default validation;
