import { useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import validation from "./SignupValidation";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
function SignupForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    passwords: "",
    confirmPassword: "",
  });
 const SITE_KEY = "6LerNzopAAAAAM2Xvfff4UCzZNBxEQoUtBek3jHYMYqi";
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [reCaptchaValue, setRecaptchaValue] = useState("");
  const captchRef = useRef();
  const { firstName, lastName, email, passwords, confirmPassword } = formData;
  const reCaptchHandler = (value) => {
    setRecaptchaValue(value);
  };

  // Handle input fields, when some value changes
  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  const navigate = useNavigate();
  axios.defaults.withCredentials = true;
  // Handle Form Submission
  const handleOnSubmit = (e) => {
    e.preventDefault();
    captchRef.current.reset();
    console.log(reCaptchaValue);
    setErrors(validation(formData));
    if (passwords !== confirmPassword) {
      return toast.error("Password and confirm Password not Match");
    } else {
      axios
        .post("https://ptl.p2psoftwares.com/api/v1/signup", {
          ...formData,
          reCaptchaValue,
        })
        .then((res) => {
          console.log(res, res.data.success);
          if (res.data.success === true) {
            toast.success("User has beeen signup successfully!");
            navigate("/login");
          } else {
            toast.error("");
          }
        })
        .catch((err) => toast(err.message));
    }

    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      passwords: "",
      confirmPassword: "",
    });
  };

  return (
    <div>
      {/* Form */}
      <form onSubmit={handleOnSubmit} className="flex w-full flex-col gap-y-4">
        <div className="flex md:flex-row  flex-col  justify-between  gap-6  ">
          <label className="md:w-5/12 w-full">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
              First Name <sup className="text-pink-400">*</sup>
            </p>
            <input
              required
              type="text"
              name="firstName"
              value={firstName}
              onChange={handleOnChange}
              placeholder="Enter first name"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
              }}
              className="w-full rounded-[0.5rem] text-black border bg-white border-[#0998F8] p-[12px] "
            />
            {errors.firstName && (
              <span className="text-red-800">{errors.firstName}</span>
            )}
          </label>
          <label className="md:w-5/12 w-full">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
              Last Name <sup className="text-pink-400">*</sup>
            </p>
            <input
              required
              type="text"
              name="lastName"
              value={lastName}
              onChange={handleOnChange}
              placeholder="Enter last name"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
              }}
              className="w-full rounded-[0.5rem] text-black border bg-white border-[#0998F8] p-[12px] "
            />
          </label>
        </div>
        <label className="w-full">
          <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
            Email Address <sup className="text-pink-400">*</sup>
          </p>
          <input
            required
            type="text"
            name="email"
            value={email}
            onChange={handleOnChange}
            placeholder="Enter email address"
            style={{
              boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
            }}
            className="w-full rounded-[0.5rem]  p-[12px] text-black border bg-white border-[#0998F8]"
          />
          {errors.email && <span className="text-red-800">{errors.email}</span>}
        </label>
        <div className="flex md:flex-row  flex-col justify-between  gap-6">
          <label className="relative md:w-5/12 w-full ">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
              Create Password <sup className="text-pink-400">*</sup>
            </p>
            <input
              required
              type={showPassword ? "text" : "password"}
              name="passwords"
              value={passwords}
              onChange={handleOnChange}
              placeholder="Enter Password"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
              }}
              className="w-full rounded-[0.5rem] text-black border bg-white border-[#0998F8] p-[12px] pr-10 "
            />
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="absolute right-3 top-[38px] z-[10] cursor-pointer"
            >
              {showPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#AFB2BF" />
              )}
            </span>
            {errors.password && (
              <span className="text-red-800">{errors.password}</span>
            )}
          </label>
          <label className="relative md:w-5/12 w-full">
            <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
              Confirm Password <sup className="text-pink-400">*</sup>
            </p>
            <input
              required
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleOnChange}
              placeholder="Confirm Password"
              style={{
                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
              }}
              className="w-full rounded-[0.5rem] text-black border bg-white border-[#0998F8] p-[12px] pr-10 "
            />
            <span
              onClick={() => setShowConfirmPassword((prev) => !prev)}
              className="absolute right-3 top-[38px] z-[10] cursor-pointer"
            >
              {showConfirmPassword ? (
                <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
              ) : (
                <AiOutlineEye fontSize={24} fill="#AFB2BF" />
              )}
            </span>
            {errors.confirmPassword && (
              <span className="text-red-800">{errors.confirmPassword}</span>
            )}
          </label>
        </div>
        <div>
          <ReCAPTCHA
            sitekey={SITE_KEY}
            onChange={reCaptchHandler}
            ref={captchRef}
          />
        </div>
        <button
          type="submit"
          className="mt-6 rounded-[8px] bg-yellow-200 py-[8px] px-[12px] font-medium text-black"
        >
          Create Account
        </button>
      </form>
    </div>
  );
}

export default SignupForm;
