import { useState, useRef } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReCAPTCHA from "react-google-recaptcha";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
function ResetPassword() {
  const navigate = useNavigate();
  const SITE_KEY = "6LerNzopAAAAAM2Xvfff4UCzZNBxEQoUtBek3jHYMYqi";
  const [reCaptchaValue, setRecaptchaValue] = useState("");
  const captchRef = useRef();
  const [formData, setFormData] = useState({
    email: "",
    passwords: "",
  });
  const reCaptchHandler = (value) => {
    setRecaptchaValue(value);
  };
  const [showPassword, setShowPassword] = useState(false);

  const { email, passwords } = formData;

  const handleOnChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    axios.defaults.withCredentials = true;
    console.log(formData);
    captchRef.current.reset();
    await axios
      .post("https://ptl.p2psoftwares.com/api/v1/resetPassword", {
        ...formData,
        reCaptchaValue,
      })
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          navigate("/Login");
        } else {
          navigate("/ResetPassword");
        }
      })
      .catch(
        (err) => toast.error(err.response.data.message),
        navigate("/ResetPassword")
      );

    setFormData({
      email: "",
      passwords: "",
      //   confirmPassword: "",
    });
  };

  return (
    <form
      onSubmit={handleOnSubmit}
      className="mt-32 mb-10 flex w-11/12  md:w-1/2  mx-auto flex-col gap-y-4"
    >
      <label className="w-full">
        <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-black">
          Email Address <sup className="text-pink-400">*</sup>
        </p>
        <input
          required
          type="text"
          name="email"
          value={email}
          onChange={handleOnChange}
          placeholder="Enter email address"
          style={{
            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
          }}
          className="w-full rounded-[0.5rem]  p-[12px] text-black border bg-white border-[#0998F8]"
        />
      </label>
      <label className="relative">
        <p className="mb-1 text-[0.875rem] leading-[1.375rem] text-richblack-5">
          Reset Your Password <sup className="text-pink-400">*</sup>
        </p>
        <input
          required
          type={showPassword ? "text" : "password"}
          name="passwords"
          value={passwords}
          onChange={handleOnChange}
          placeholder="Enter Reset Password"
          style={{
            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
          }}
          className="w-full rounded-[0.5rem]  p-[12px] pr-12 text-black border bg-white border-[#0998F8]"
        />
        <span
          onClick={() => setShowPassword((prev) => !prev)}
          className="absolute right-3 top-[38px] z-[10] cursor-pointer"
        >
          {showPassword ? (
            <AiOutlineEyeInvisible fontSize={24} fill="#AFB2BF" />
          ) : (
            <AiOutlineEye fontSize={24} fill="#AFB2BF" />
          )}
        </span>
      </label>
      <div>
        <ReCAPTCHA
          sitekey={SITE_KEY}
          onChange={reCaptchHandler}
          ref={captchRef}
        />
      </div>

      <button
        type="submit"
        className="mt-6 rounded-[8px] bg-yellow-200 py-[8px] px-[12px] font-medium text-black"
      >
        Submit
      </button>
    </form>
  );
}

export default ResetPassword;
