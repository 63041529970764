import React from "react";

export const TermCondition = () => {
  const ProhibitedItem = [
    {
      title: "4.1 Violation of Applicable Laws:",
      text: "Any action that violates the laws, rules, or regulations of any jurisdiction relevant to the user is strictly prohibited.",
    },
    {
      title: "4.2 Breach of Terms:",
      text: " Users are not allowed to breach or violate the terms stipulated in these Terms or any other documents governing the use of ProtoLink Services.",
    },
    {
      title: "4.3 Unauthorized Access:",
      text: " Users may not permit others to access ProtoLink Services through their wallet or a blockchain address they control, or engage in transactions on behalf of any person other than the owner of the Wallet.",
    },
    {
      title: "4.4 Interference with Operations:",
      text: " Performing or attempting actions that would disrupt the normal operation of ProtoLink Services or impact the experience of other users is strictly forbiddens.",
    },
    {
      title: "4.5 Fraudulent Activities: ",
      text: `Engaging in or facilitating any fraudulent, deceptive, or manipulative transaction activity, including activities like "front running," "wash trading," or "pump and dump schemes," is strictly prohibited.
`,
    },
    {
      title: "4.6 Illegal Financial Activities: ",
      text: "Users may not engage in or knowingly facilitate any money laundering, terrorist financing, or other illegal activities.",
    },
    {
      title: "4.7 Unauthorized Access to Systems:",
      text: " Accessing or attempting to access non-public systems, programs, data, or services is strictly prohibited.",
    },
    {
      title: " 4.8 Unauthorized Distribution:",
      text: " Copying, reproducing, republishing, uploading, posting, transmitting, reselling, or distributing any data, content, or any part of ProtoLink Services is not permitted, except as expressly allowed by applicable laws.",
    },
    {
      title: " 4.9 Reverse Engineering:",
      text: " Users are not allowed to reverse engineer or attempt to reverse engineer ProtoLink Services, except as expressly permitted by applicable law.",
    },
  ];
  const generalItem = [
    {
      title: "11.1 Entire Terms",
      text: `These Terms represent the complete agreement between you, the Operator, and the Developer regarding your use of the ProtoLink Services. Section titles are for convenience only and do not have legal or contractual implications. The term "including" implies "including without limitation."`,
    },
    {
      title: "11.2 Severability ",
      text: "11.2 Severability If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions will remain unaffected, and the invalid or unenforceable provision will be modified to the maximum extent allowed by law to make it valid and enforceable.",
    },
    {
      title: " 11.3 Relationship of the Parties",
      text: "This Agreement does not create a partnership, agency relationship, or joint venture between the Parties or their respective employees, representatives, or agents.",
    },
    {
      title: "11.4 Assignment",
      text: " You may not assign, subcontract, delegate, or transfer these Terms without prior written consent from the Operator and the Developer. The Operator and the Developer may freely assign these Terms, and the terms and conditions will be binding on assignees.",
    },
    {
      title: "11.5 Changes ",
      text: `
Unless required by applicable laws, these Terms may be revised occasionally. Substantial changes will be communicated, and your acceptance of the updated Terms during your next use of the ProtoLink Services will be required. Continued use following notice indicates acknowledgment and agreement to the updated terms.`,
    },
    {
      title: "11.6 Waiver ",
      text: "A waiver by the Operator or the Developer of any right or remedy under these Terms is effective only in writing, executed by an authorized representative. A waiver applies only to the specific circumstances and does not prevent future enforcement. The failure to exercise a right or remedy does not waive that right or remedy, and no waiver is valid unless in writing and executed by an authorized representative.",
    },
  ];

  const DisclaimerItem = [
    {
      title: "  7.1 Disclaimer",
      text: `The ProtoLink Services and the website protolink.io are provided on an "as-is" and "as available" basis. Both the Operator and the Developer expressly disclaim all warranties and conditions, whether express, implied, or statutory, including but not limited to merchantability, fitness for a particular purpose, accuracy, and non-infringement. There is no warranty that the ProtoLink Services will meet requirements or be uninterrupted, timely, secure, error-free, accurate, reliable, free of viruses, complete, legal, or safe. If applicable law requires warranties, they are limited to sixty (60) days from the date of first use. The Operator and the Developer do not endorse third parties and are not responsible for transactions with them. Users agree that the Operator, the Developer, and their respective affiliates are not liable for losses resulting from interactions with third parties.`,
    },
    {
      title: "7.2 Limitation of Liability",
      text: " To the maximum extent permitted by law, neither the Operator, the Developer, nor their respective affiliates shall be liable for lost profits, lost data, or any indirect, consequential, exemplary, incidental, special, or punitive damages arising from ProtoLink Services use. Access to and use of the ProtoLink Services is at the user's own risk. The Operator, the Developer, and their respective affiliates are not liable for losses or damages arising from failure to secure private keys, unauthorized access, or transactions involving wallets. Liability is limited to the transaction fees paid by the user in the preceding twelve (12) months. More than one claim does not enlarge this limit.",
    },
    {
      title: " 7.3 Indemnification",
      text: "Users agree to indemnify and hold the Operator, the Developer, and their respective affiliates harmless from any loss, claim, or demand related to ProtoLink Services use, violation of these Terms, or violation of applicable laws. The relevant indemnified party has the right to assume the defense of any matter requiring indemnification at the user's expense.",
    },
    {
      title: "7.4 Taxes",
      text: "Users are responsible for determining and paying taxes associated with ProtoLink Services transactions. The Operator and the Developer are not responsible for tax implications or reporting.",
    },
  ];

  const WaiversItem = [
    {
      title: "5.1 Limitation of Liability: ",
      text: `You acknowledge and agree that the Operator, the Developer, and their Affiliates shall not be held liable for any direct, indirect, special, incidental, consequential, or other losses, whether in tort, contract, or otherwise. This includes, but is not limited to, losses of funds, assets, revenue, income, profits, or loss of use or data, arising from or related to your use of ProtoLink Services, ProtoLink Smart Contracts, ProtoLink Blockchain, the Website, or Direct Access. For the purpose of these Terms, "Affiliates" refers to the owners, directors, officers, employees, advisors, agents, and companies in which such entity has an interest.`,
    },
    {
      title: " 5.2 Class Action Waiver: ",
      text: "You agree not to initiate or participate in any class action lawsuit or class-wide arbitration against the Operator, the Developer, and/or their respective Affiliates.",
    },
    {
      title: "5.3 Waiver of Claims:",
      text: " By accepting these Terms, you waive all rights, claims, and causes of action, whether present or future, under law (including any tortious claims) or contract against the Operator, the Developer, and their respective Affiliates in connection with your use of ProtoLink Services, ProtoLink Smart Contracts, ProtoLink Blockchain, the Website, and/or Direct Access.",
    },
  ];
  return (
    <section className="mb-16 pt-28">
      <div className="max-w-6xl m-auto text-sm text-lightGrey px-3">
        <p className="mb-3">
          Welcome to ProtoLink! Please carefully review these Terms of Use
          ("Terms") as they establish legally binding terms and conditions
          between you, the Operator, and the Developer. These Terms govern your
          use of the ProtoLink Services, including (a) the website at
          https://ProtoLink.io/ (the "Website"); (b) the ProtoLink Smart
          Contracts; and (c) the ProtoLink Blockchain (collectively referred to
          as the "ProtoLink Services"). This includes usage via the Website,
          command line, locally installed programs, Software Development Kits,
          software code, and blockchain and smart contract explorers
          (collectively termed "Direct Access").
        </p>
        <p className="mb-3">
          By accessing or using the ProtoLink Services, you (referred to as
          "you" or the "User") agree to abide by these Terms on behalf of
          yourself and any entity you represent. You further confirm that you
          have the right and authority to do so and that you are fully able and
          competent to enter into and comply with these Terms.
        </p>
        <ul className="ml-5 text-xl font-semibold  my-6">
          <li className="list-disc">Scope and Limitations:</li>
          <li></li>
        </ul>

        <p className="mb-10">
          ProtoLink Services, including the Website, ProtoLink Smart Contracts,
          and the ProtoLink Blockchain, are not intended for
          <ul className="ml-5   my-1">
            <li className="list-item">
              {" "}
              (a) access or use by Excluded Persons;
            </li>
            <li className="list-item">
              {" "}
              (b) access or use by any person or entity in, or accessing or
              using the Website from, an Excluded Jurisdiction. Excluded Persons
              should refrain from accessing or using the Website, ProtoLink
              Services, ProtoLink Smart Contracts, and the ProtoLink Blockchain.
            </li>
          </ul>
        </p>

        <ul className="ml-5 text-xl font-semibold  my-6">
          <li className="list-disc">Ownership and Operation:</li>
          <li></li>
        </ul>

        <p className="mb-10">
          The Website is owned and operated by Protolink Ltd. ("Operator"), an
          entity formed under the laws of Kirgizstan. The ProtoLink Smart
          Contracts and the ProtoLink Blockchain are copyrighted works belonging
          to ProtoLink Ltd.("Developer"), also an entity formed under the laws
          of the Kirgizstan.
        </p>

        <ul className="ml-5 text-xl font-semibold  my-6">
          <li className="list-disc">Acceptance of Terms:</li>
          <li></li>
        </ul>

        <p className="mb-10">
          You will be prompted to accept these Terms before connecting a wallet
          to access and use the ProtoLink Services. By accessing and using the
          ProtoLink Services, you explicitly accept these Terms.
        </p>

        <ul className="ml-5 text-xl font-semibold  my-6">
          <li className="list-disc text-xl font-semibold">
            ProtoLink Multi-chain Interoperability Infrastructure
          </li>
        </ul>

        <p className="mb-10">
          ProtoLink is at the forefront of revolutionizing the blockchain
          landscape by introducing a robust Multi-chain Interoperability
          Infrastructure. Our mission is to empower users to seamlessly bridge
          information and assets across various layer-1 and layer-2 blockchains
          in a fully non-custodial manner. This infrastructure facilitates
          decentralized Multi-chain Interoperability Activities through:
          <ul className="ml-5     my-1">
            <li className="list-item text-xl  font-semibold my-3">
              {" "}
              (a) Deposits, Withdrawals, and Management:
            </li>
            <p>
              Users can deposit, withdraw, and manage their digital assets at
              designated smart contract addresses associated with ProtoLink
              Smart Contracts. These smart contracts, defined in detail on
              GitHub at <a href=" https://github.com/"> https://github.com/</a>,
              enable users to pool their digital assets as liquidity. This
              liquidity is essential for other users to conduct non-custodial
              cross-chain asset bridging activities.
            </p>
            <li className="  text-xl font-semibold my-3">
              (b) Utilizing ProtoLink Smart Contracts and Blockchain:
            </li>
            <p>
              ProtoLink employs smart contracts, known as ProtoLink Smart
              Contracts, which are coded based on various blockchain standards
              and programming languages. These contracts, available on GitHub,
              facilitate non-custodial cross-chain asset and information
              bridging. Additionally, ProtoLink operates a specialized Proof of
              Stake blockchain network with staking functionality on Ethereum,
              connecting to ProtoLink Smart Contracts on various blockchains.
              This network, termed ProtoLink Blockchain, acts as the backbone
              for executing Multi-chain Interoperability Activities.
            </p>
            <li className=" my-3 list-disc text-xl font-semibold ">
              ProtoLink.io - User Interface and Beyond:{" "}
            </li>
            <p>
              The ProtoLink.io website serves as a user-friendly interface
              providing convenient access to ProtoLink Smart Contracts and
              ProtoLink Blockchain. However, users can also engage with
              ProtoLink Smart Contracts and ProtoLink Blockchain directly
              through Direct Access. Developer, through Deployment, has
              strategically placed ProtoLink Smart Contracts on multiple
              blockchains, and ProtoLink Blockchain connects to various
              blockchains, ensuring non-custodial and decentralized bridging of
              information and assets.
            </p>
            <li className=" my-3 list-disc text-xl font-semibold">
              Decentralized Accessibility:{" "}
            </li>
            <p>
              As ProtoLink Smart Contracts are deployed on public blockchains
              and ProtoLink Blockchain is publicly accessible with staking
              functionality on Ethereum, they may also be interacted with
              through other smart contract decentralized applications ("dApps").
              This decentralized accessibility aligns with our commitment to
              fostering an open and inclusive ecosystem.
            </p>
            <li className=" my-3 text-xl font-semibold">
              1. User Interaction and Direct Access:
            </li>
            <p>
              The Website, along with related technology, empowers users to read
              and display data associated with any connected blockchain address
              for which they control the private key. Users can also interact
              with ProtoLink Smart Contracts and ProtoLink Blockchain through
              standardized transaction messages, participating seamlessly in
              supported Multi-chain Interoperability Activities. For users with
              technical expertise, the option to generate transaction messages
              and interact with ProtoLink directly through Direct Access is
              available, without any prohibition or separate license
              requirements from the Developer.
            </p>
            <li className=" my-3 text-xl font-semibold">
              1.1. Wallet Connection
            </li>
            <p>
              To engage in transactions using the ProtoLink Smart Contracts via
              the Website or Direct Access, users are required to connect a
              wallet ("Wallet") to the ProtoLink Smart Contracts. For users
              accessing the Website, the option to connect a Metamask wallet or
              any other wallet supported by the Website is available. This
              connection is essential for accessing ProtoLink Smart Contracts
              and participating in Multi-chain Interoperability Activities.
            </p>
            <li className=" text-xl my-3 font-semibold">1.2. Governance</li>
            <p>
              The oversight of the Website, excluding Direct Access, falls under
              the purview of the Operator. Operator retains the right to modify
              or discontinue support for the Website at its discretion. However,
              the maintenance and modification of the ProtoLink Smart Contracts
              and ProtoLink Blockchain rest solely with the Developer. Operator
              does not have authority over these elements.
            </p>
            <li className=" my-3 text-xl font-semibold">
              1.3. Transaction Fees
            </li>
            <p>
              Each transaction executed through ProtoLink Smart Contracts and
              ProtoLink Blockchain may incur a transaction fee. Developer, at
              its sole discretion, holds the right to modify these fees, which
              can be payable in either the source digital asset or the
              destination digital asset. Users will be presented with the
              transaction fee for a specific transaction during the initiation,
              and user acceptance is required before execution. Users accessing
              ProtoLink Smart Contracts and ProtoLink Blockchain via Direct
              Access will encounter a similar fee schedule.
            </p>
            <li className=" text-xl my-3 font-semibold">1.4. License</li>
            <p>
              Users are granted a non-exclusive, non-transferable, revocable,
              limited license to electronically access and use the Website as
              outlined in this Agreement. Sublicensing of the Website is not
              permitted. This license may be revoked or terminated if users
              violate the terms of this Agreement or if termination occurs
              pursuant to Section 6 herein. ProtoLink Smart Contracts, being
              open-source software, are accessible under the GNU GPL v3.0
              License. For further details on the License applicable to the
              ProtoLink Smart Contracts, please refer to the protocol
              documentation at <a href="https://github.com/"></a>
              https://github.com/.
            </p>
            <li className=" text-xl my-3 font-semibold">
              2. Usage of ProtoLink Smart Contracts and the ProtoLink Blockchain
            </li>
            <li className=" text-xl my-3 font-semibold">
              2.1 Cross-chain Asset Bridging Process:
            </li>
            <p>
              The seamless bridging of assets across different blockchains using
              ProtoLink involves the following steps:
            </p>
            <p className=" my-3">
              <span className=" text-xl font-semibold">
                (a) User Connection and Asset Transfer:{" "}
              </span>
              A user initiates the process by connecting their digital wallet
              through any smart contract interaction interface, including the
              Website. The user then executes a series of smart contract
              function calls to transfer their digital assets (referred to as
              "Supported Digital Assets") supported by ProtoLink to ProtoLink
              Smart Contracts on the Source Chain. Simultaneously, the user
              specifies the intention to bridge the transferred asset to a
              different blockchain (the "Destination Chain") while considering
              acceptable pricing and fees.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (b) Reflection on ProtoLink Blockchain:
              </span>
              The user's transferred Supported Digital Assets on the Source
              Chain are reflected on the ProtoLink Blockchain. A consensus is
              reached to determine the receiving amount on the Destination
              Chain. A Proof-of-Stake (PoS) stake-weighted multi-signature
              message, known as the Multisig Message, is generated on the
              ProtoLink Blockchain.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (c) Submission to Destination Chain:
              </span>
              The generated Multisig Message is submitted to ProtoLink Smart
              Contracts on the Destination Chain through a smart contract
              function call facilitated by the ProtoLink Blockchain.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (d) Reception of Bridged Assets:
              </span>
              The user receives the bridged assets on the Destination Chain
              directly into their digital wallet.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (e) Handling Failures:
              </span>
              In the event of a failure at any step, such as pricing mismatch or
              insufficient fees, the user has the ability to reclaim the
              initially transferred assets on the Source Chain. The reclaimed
              amount would exclude transaction fees associated with ProtoLink
              Smart Contracts and ProtoLink Blockchain.
            </p>
            <p className="list-disc text-xl font-semibold">
              2.2 Providing Liquidity in Liquidity Pool Process:
            </p>
            <p>
              The process of a user providing liquidity on different chains to
              facilitate cross-chain asset transfers for other users via
              ProtoLink unfolds as follows:
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (a) Commitment to Liquidity Pool:
              </span>
              The user specifies the amount of their Supported Digital Assets
              intended for commitment to deploying liquidity in Liquidity Pool
              smart contracts, integral to ProtoLink Smart Contracts.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (b) Participation Transaction:
              </span>
              To commit and deploy the specified Supported Digital Assets to the
              Liquidity Pool, the user initiates an irrevocable transaction
              through a smart contract function call on the blockchain. This
              transaction is known as the Participation Transaction.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (c) Reflection on ProtoLink Blockchain:
              </span>
              The initiated Participation Transaction is reflected on the
              ProtoLink Blockchain. A consensus is achieved to update the
              available liquidity balance in Liquidity Pools across different
              blockchains.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (d) Utilization of Liquidity:
              </span>
              As another user initiates a cross-chain asset transfer, the
              ProtoLink Blockchain may reach decentralized consensus to utilize
              the liquidity committed by the providing user to facilitate the
              transfer.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (e) Liquidity Balance Update:
              </span>
              Upon completion of the transfer, the user's liquidity balance in
              different Liquidity Pools is updated by the ProtoLink Blockchain
              through a decentralized consensus process. The update is carried
              out according to the pricing and fees charged for the transfer.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (f) Withdrawal of Liquidity:
              </span>
              The providing user can initiate a withdrawal of liquidity from
              Liquidity Pools. This involves specifying the percentage of
              Supported Digital Assets to be withdrawn from different Liquidity
              Pools and the target blockchain for withdrawal (Withdrawal Chain).
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (g) Withdrawal Transaction:
              </span>
              The user initiates an irrevocable transaction on the ProtoLink
              Blockchain to effectuate the withdrawal.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (h) Decentralized Consensus for Withdrawal:
              </span>
              The ProtoLink Blockchain reaches decentralized consensus to
              determine the receiving amount on the Withdrawal Chain based on
              pricing and fee configurations. A Proof-of-Stake (PoS)
              stake-weighted multi-signature message (Multisig Message) is
              generated on the ProtoLink Blockchain.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (i) Submission to Withdrawal Chain:
              </span>
              The user submits the Multisig Message to ProtoLink Smart Contracts
              on the Withdrawal Chain through a smart contract function call
              transaction.
            </p>
            <p className="my-3">
              <span className=" text-xl font-semibold">
                (j) Receipt of Withdrawn Assets:
              </span>
              The user receives the withdrawn Supported Digital Assets on the
              Withdrawal Chain, with the amount adjusted for fees and pricing
              configurations.
            </p>
            <li className=" text-xl  my-3 font-semibold">
              3. Representations & Warranties
            </li>
            <p>
              By utilizing ProtoLink Services, you explicitly make the following
              representations and warranties:
            </p>
            <li className=" my-3 text-xl font-semibold">
              3.1 Legal Permissions:
            </li>
            <p>
              You confirm that you are legally permitted to use ProtoLink
              Services in your jurisdiction, including ownership of digital
              assets and interaction with the services. You are responsible for
              compliance with local laws and acknowledge that neither the
              Operator nor the Developer is liable for your adherence or
              non-adherence to such laws.
            </p>
            <li className=" my-3 text-xl font-semibold">
              3.2 No Breach or Violation:
            </li>
            <p>
              You assert that agreeing to these Terms and using ProtoLink
              Services does not result in a breach, default, or violation of any
              applicable law or contractual agreement to which you are a party.
            </p>
           
            <li className=" my-3 text-xl font-semibold">
              3.3 Non-Excluded Person:
            </li>
            <p>
              You declare that you are not an Excluded Person and are not in an
              Excluded Jurisdiction. An Excluded Person includes individuals or
              entities in specific jurisdictions outlined, and this definition
              extends to those listed in the United Nations Consolidated List or
              prohibited by applicable laws.
            </p>
            <li className=" my-3 text-xl font-semibold">
              3.4 Limited Authorization:
            </li>
            <p>
              You ensure that only you, and not anyone else, will attempt to
              access ProtoLink Smart Contracts or engage in Multi-chain
              Interoperability Activities using a Wallet you own or a blockchain
              address where you control the private keys.
            </p>
            <li className=" my-3 text-xl font-semibold">3.5 Non-Disruption:</li>
            <p>
              You commit not to disrupt, interfere with, or negatively impact
              the normal functioning of ProtoLink Services. Prohibited actions
              include taking advantage of vulnerabilities or intentionally
              abusing the service's design.
            </p>
            <li className=" my-3 text-xl font-semibold">
              {" "}
              3.6 Sophistication in Blockchain Technologies:{" "}
            </li>
            <p>
              You assert your proficiency in blockchain technologies, including
              Ethereum network, ERC-20 tokens, liquidity pool pricing, and
              ProtoLink's unique features. You confirm that your evaluation and
              understanding of ProtoLink Services are independent, and you
              haven't relied on information provided by the Operator or
              Developer.
            </p>
            <li className=" my-3 text-xl font-semibold">
              3.7 Truthfulness of Representations:
            </li>
            <p>
              All the above representations and warranties are true, complete,
              accurate, and not misleading from the moment of accepting these
              Terms. They are considered reaffirmed each time you use ProtoLink
              Services.
            </p>
            <li className=" text-xl  my-3 font-semibold">4. Prohibited Use</li>
            <p>
              Users of ProtoLink Services are strictly prohibited from engaging,
              either directly or indirectly, in any of the following activities,
              constituting "Prohibited Uses":
            </p>
            {ProhibitedItem.map((item) => (
              <p>
                <li className=" my-3 text-xl font-semibold" key={item}>
                  {item.title}
                </li>
                <p>{item.text}</p>
              </p>
            ))}
            <li className=" text-xl  my-3 font-semibold">5. Waivers</li>
            
            {WaiversItem.map((item) => (
              <p>
                <li className=" my-3 text-xl font-semibold" key={item}>
                  {item.title}
                </li>
                <p>{item.text}</p>
              </p>
            ))}
            <p>
              <li className=" my-3 text-xl font-semibold">6. Termination</li>
              <p>
                These Terms will be effective for the duration of your use of
                the ProtoLink Services. The Operator or the Developer reserves
                the right to suspend or terminate your access to the ProtoLink
                Services at any time and for any reason at their sole
                discretion, including instances of ProtoLink Services usage that
                violate these Terms. In the event of termination, your right to
                access and use the ProtoLink Services will cease immediately.
                The Operator and the Developer bear no liability for any
                consequences arising from the termination of your rights under
                these Terms, including potential blacklisting of any network
                address provided.
              </p>
            </p>
            <h3 className=" text-xl  my-3 font-semibold">
              7.Disclaimers and Limitation of Liability
            </h3>
            {DisclaimerItem.map((item) => (
              <p>
                <li className=" my-3 text-xl font-semibold" key={item}>
                  {item.title}
                </li>
                <p>{item.text}</p>
              </p>
            ))}
            <h3 className=" text-xl  my-3 font-semibold">
              7.5 Potential Risks Associated with Use of the Website, ProtoLink
              Smart Contracts, and the ProtoLink
            </h3>

            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.1 Exploits:
              </span>{" "}
              The Website, ProtoLink Smart Contracts, and the ProtoLink
              Blockchain may be subject to exploits. The Operator and the
              Developer are not responsible for exploits.
            </li>
            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.2 Private Keys:
              </span>{" "}
              Users are solely responsible for securing private keys.
              Unauthorized access may result in the loss of digital assets.
            </li>
            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.3 Value Fluctuation:
              </span>{" "}
              Digital asset values may fluctuate, and the Operator and the
              Developer make no guarantees about secondary market prices.
            </li>
            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.4 Blockchain Risks:
              </span>{" "}
              Risks include the loss of private keys, theft, value fluctuation,
              disruptions due to network congestion, hard forks, and
              irreversible transactions.
            </li>
            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.5 Support Changes:
              </span>{" "}
              Support for the Website or ProtoLink Smart Contracts may be
              modified or discontinued at any time.
            </li>
            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.6 Blockchain Forks:
              </span>{" "}
              In the event of a blockchain fork, ProtoLink Services may not
              halt. Transactions may be disrupted.
            </li>
            <li className="my-3 pl-5">
              <span className=" list-disc text-xl font-semibold">
                7.5.7 Transaction Fees:
              </span>{" "}
              Network transaction fees on blockchain networks connected to
              ProtoLink are the user's responsibility, and fees may fluctuate.
              Users are also responsible for third-party fees incurred during
              ProtoLink Services use.
            </li>
            <p>
              <li className=" my-3 text-xl font-semibold">
                8. Dispute Resolution
              </li>
              <p>
                Subject to Clause 5 of these Terms, any claim, suit, or dispute
                arising from or related to these Terms, including questions
                about existence, validity, or termination, shall be resolved
                through arbitration in accordance with the arbitration rules of
                the Kirgizstan. The arbitration panel, consisting of three (3)
                arbitrators, will be formed with each party appointing one
                arbitrator, and those two appointed arbitrators selecting the
                third. Decisions will be reached by a majority vote. Claims must
                be brought individually, not on behalf of a class, and may not
                be consolidated with the claims of others. The seat of
                arbitration is the Kirgizstan, and English is the language of
                arbitration. Judgments on awards may be entered by any court
                with jurisdiction.
              </p>
            </p>
            <p>
              <li className=" my-3 text-xl font-semibold">
                9. Electronic Communications with the Operator
              </li>
              <p>
                Communications between you and the Operator, whether through the
                Website or electronic mail, constitute electronic means of
                communication. By accepting these Terms, you consent to
                receiving communications from the Operator in electronic form.
                You agree that all terms, conditions, agreements, notices,
                disclosures, and other communications provided by the Operator
                electronically satisfy legal requirements. This agreement does
                not affect your non-waivable rights under applicable law.
              </p>
            </p>
            <p>
              <li className=" my-3 text-xl font-semibold">
                10. Governing Law and Jurisdiction
              </li>
              <p>
                These Terms and any disputes or claims arising from or related
                to them, including non-contractual disputes or claims, are
                governed by the law of the Kirgizstan. You agree that the courts
                of the Kirgizstan have exclusive jurisdiction over any dispute
                or claim related to these Terms, including the enforcement of
                the arbitration agreement in Section 8. The appropriateness or
                availability of the ProtoLink Services in certain jurisdictions
                is not guaranteed. The Operator, the Developer, and their
                respective Affiliates do not warrant that the Services or any
                part thereof are suitable or available for use in any specific
                jurisdiction. By accessing the ProtoLink Services, you do so at
                your own risk, on your own initiative, and are responsible for
                complying with all applicable local laws, rules, and
                regulations.
              </p>
            </p>
            <h3 className=" my-3 text-xl font-semibold">11.General</h3>
            {generalItem.map((item) => (
              <p>
                <li className=" my-3 text-xl font-semibold" key={item}>
                  {item.title}
                </li>
                <p>{item.text}</p>
              </p>
            ))}
          </ul>
        </p>
      </div>
    </section>
  );
};
